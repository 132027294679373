import React, { useRef, useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Lottie from "lottie-react";
import homeAnimation from "../img/system-solid-41-home-hover-home-2.json";
import purchaseAnimation from "../img/system-solid-6-shopping-hover-shopping.json";

import HomeSharpIcon from "@mui/icons-material/HomeSharp";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import MenuIcon from "@mui/icons-material/Menu"; // Icon for hamburger menu
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext"; // Import useAuth from your AuthContext
import { useMediaQuery } from "@mui/material"; // Hook to detect screen size
import { useTheme } from "@mui/material/styles";

export default function Navbar({ handleDrawerToggle }) {
  const homeLottieRef = useRef(null);
  const purchaseLottieRef = useRef(null);

  const navigate = useNavigate();
  const { isLoggedIn, logout } = useAuth(); // Use AuthContext for authentication state
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if screen is small (mobile)
  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for the menu
  const open = Boolean(anchorEl);
  const [drawerOpen, setDrawerOpen] = useState(false); // State to manage drawer
  const location = useLocation(); // Hook to get current route

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget); // Set anchor element to open the menu
  };
  const handleMenuClose = () => {
    setAnchorEl(null); // Close the menu
  };
  const handleLogout = () => {
    logout(); // Call logout from AuthContext
    handleMenuClose();
    navigate("/login"); // Redirect to the login page
  };
  const handleLoginClick = () => {
    navigate("/login"); // Navigate to the login page
  };
  const handleSignupClick = () => {
    navigate("/signup"); // Navigate to the signup page
  };
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open); // Open or close the drawer
  };

  const drawer = (
    <Box
      sx={{ width: 250 }}
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem
          button
          component={Link}
          to="/layout/home"
          selected={location.pathname === "/layout/home"}
        >
          <HomeSharpIcon sx={{ marginRight: 1 }} />
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/layout/purchase"
          selected={location.pathname === "/layout/purchase"}
        >
          <LocalMallIcon sx={{ marginRight: 1 }} />
          <ListItemText primary="Purchase" />
        </ListItem>
        {!isLoggedIn ? (
          <>
            <ListItem
              button
              onClick={handleSignupClick}
              selected={location.pathname === "/signup"}
            >
              <ListItemText primary="Signup" />
            </ListItem>
            <ListItem
              button
              onClick={handleLoginClick}
              selected={location.pathname === "/login"}
            >
              <ListItemText primary="Login" />
            </ListItem>
          </>
        ) : (
          <>
            <ListItem button onClick={handleProfileMenuOpen}>
              <Avatar
                alt="Profile Picture"
                src="/path-to-profile-image.jpg"
                sx={{ marginRight: 1 }}
              />
              <ListItemText primary="Profile" />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemText primary="Logout" />
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "#0083B9" }}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* Left Side: App name */}
        <Typography
          variant="h5"
          textAlign="center"
          sx={{
            color: "white",
            fontWeight: "bold",
          }}
        >
          MAHASTUDY
        </Typography>
        {/* Right Side: Desktop View or Mobile View */}
        {isMobile ? (
          <>
            {/* Mobile view: Show hamburger menu */}
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              {drawer}
            </Drawer>
          </>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {/* Desktop view: Show buttons */}
            <Button
        component={Link}
        to="/layout/home"
        sx={{ color: "white", marginRight: 2 }}
        style={{
          backgroundColor:
            location.pathname === "/layout/home" ? "#005f73" : "transparent",
        }}
        onMouseEnter={() => homeLottieRef.current.play()}
        onMouseLeave={() => homeLottieRef.current.stop()}
      >
        <Lottie
          lottieRef={homeLottieRef}
          animationData={homeAnimation}
          style={{ height: 24, width: 24 }}
          autoplay={false} // Start animation only on hover
          loop={false} // Stop animation once it plays through
        />
        Home
      </Button>

      <Button
        component={Link}
        to="/layout/purchase"
        sx={{ color: "white", marginRight: 2 }}
        style={{
          backgroundColor:
            location.pathname === "/layout/purchase" ? "#005f73" : "transparent",
        }}
        onMouseEnter={() => purchaseLottieRef.current.play()}
        onMouseLeave={() => purchaseLottieRef.current.stop()}
      >
        <Lottie
          lottieRef={purchaseLottieRef}
          animationData={purchaseAnimation}
          style={{ height: 24, width: 24 }}
          autoplay={false}
          loop={false}
        />
        Purchase
      </Button>

            {/* Conditionally render Signup, Login, or Profile */}
            {!isLoggedIn ? (
              <>
                <Button
                  variant="contained"
                  onClick={handleSignupClick}
                  sx={{
                    marginLeft: 2,
                    backgroundColor: "white",
                    color: "black",
                    "&:hover": {
                      // Prevent color change on hover
                      backgroundColor: "white",
                      color: "black",
                    },
                  }}
                >
                  Signup
                </Button>
                <Button
                  variant="contained"
                  onClick={handleLoginClick}
                  sx={{
                    marginLeft: 2,
                    backgroundColor: "white",
                    color: "black",
                    "&:hover": {
                      // Prevent color change on hover
                      backgroundColor: "white",
                      color: "black",
                    },
                  }}
                >
                  Login
                </Button>
              </>
            ) : (
              <>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls="profile-menu"
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <Avatar
                    alt="Profile Picture"
                    src="/path-to-profile-image.jpg"
                  />
                </IconButton>
                <Menu
                  id="profile-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </>
            )}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}
