import axios from "axios";

const API_URL = "https://mahastudy.in:8443";

export const getAllMaterialTypes = async () => {
    try {
      const response = await axios.get(`${API_URL}/getalltype`);
      return response.data;
    } catch (error) {
      console.error("Error fetching materials:", error);
      throw error;
    }
  };
  
  // Fetch all categories
  export const getAllCategories = async () => {
    const response = await axios.get("https://mahastudy.in:8443/getallCategory");
    return response.data;
  };
  
  // Fetch subcategories by category name
  export const getSubcategoriesByCategory = async (categoryName) => {
    const response = await axios.get(
      `https://mahastudy.in:8443/subcategoryByCategory?categoryName=${categoryName}`
    );
    return response.data;
  };
  export async function getAllCategoriesByMaterialType(materialTypeName) {
    const response = await fetch(`${API_URL}/bymaterialtype?materialTypeName=${materialTypeName}`);
    
    if (!response.ok) {
      throw new Error("Failed to fetch categories");
    }
    
    const data = await response.json();
    return data;
  }