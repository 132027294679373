import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Container,
  Card,
  CardContent,
  CardActions,
  Grid,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

const LoginPage = () => {
  const navigate = useNavigate();
  const { login, setUser } = useAuth(); // Get login and setUser function from context
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    try {
      // Perform the login request
      const loginResponse = await fetch("https://mahastudy.in:8443/Userlogin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: username, password }),
      });

      console.log("Login Response:", loginResponse); // Check the login response

      if (loginResponse.ok) {
        // On successful login, fetch the user data using email
        const userResponse = await fetch(
          `https://mahastudy.in:8443/getUserByemail?email=${username}`
        );

        console.log("User Response:", userResponse); // Check the user data response

        if (userResponse.ok) {
          const userData = await userResponse.json();
          console.log("User Data:", userData); // Log user data for debugging

          setUser(userData); // Store user data in Auth context
          login(userData); // Call login to set the user in context

          // Now navigate to the home page and pass the user data
          navigate("/layout/home", { state: { user: userData } });
        } else {
          setError("Failed to retrieve user data.");
        }
      } else {
        const message = await loginResponse.text();
        console.log("Error Message:", message); // Check error message from server
        setError(message || "Login failed. Please try again.");
      }
    } catch (error) {
      console.error("Error occurred during login:", error); // Log any client-side errors
      setError("An error occurred. Please try again later.");
    }
    setLoading(false);
  };

  const handleCreateAccount = () => {
    navigate("/signup");
  };

  return (
    <>
      <Typography
        fontSize="70px"
        textAlign="center"
        color="#3498DB"
      
      >
    MAHASTUDY
      </Typography>

      <div
        style={{
          minHeight: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container
          component="main"
          maxWidth="md"
          style={{
            padding: "16px",
            borderRadius: "8px",
          }}
        >
          <Grid container spacing={2} alignItems="center">
            {/* Left side with the GIF */}
            <Grid item xs={12} sm={6}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginRight: "70px",
                }}
              >
                <img
                  src="https://cdn.dribbble.com/users/218750/screenshots/1793485/media/c2747cb160664ad7825abdaceda5d805.gif"
                  alt="GIF"
                  style={{ maxWidth: "500px", height: "330px" }}
                />
              </div>
            </Grid>

            {/* Right side with the login form */}
            <Grid item xs={12} sm={6}>
              <Card
                elevation={8}
                style={{ marginRight: 40, backgroundColor: "#f5f5f5" }}
              >
                <CardContent>
                  <Typography
                    component="h1"
                    variant="h5"
                    align="center"
                 
                    style={{
                      marginBottom: "16px",
                      fontWeight: "bold",
                      color: "#3498DB ",
                    }}
                  >
                    LOG IN
                  </Typography>
                  <form
                    noValidate
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleLogin();
                    }}
                  >
                    <Stack spacing={2} marginTop={2}>
                      <TextField
                        required
                        fullWidth
                        label="Username"
                        variant="outlined"
                        autoFocus
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        InputLabelProps={{ className: "required-asterisk" }}
                      />
                      <TextField
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        variant="outlined"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        InputLabelProps={{ className: "required-asterisk" }}
                      />
                      {error && (
                        <Typography
                          color="error"
                          variant="body2"
                          align="center"
                        >
                          {error}
                        </Typography>
                      )}
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        // color="primary"
                        disabled={loading} // Disable button while loading
                        sx={{
                         // backgroundColor: "green",
                       
                        }}
                      >
                        {loading ? "Logging in..." : "Login"}
                      </Button>
                    </Stack>
                  </form>
                </CardContent>
                <CardActions>
                  <Grid
                    container
                    justifyContent="flex-end"
                    style={{ marginTop: "16px" }}
                  >
                    <Grid item>
                      <Button
                        onClick={handleCreateAccount}
                        sx={{
                     
                        }}
                      >
                        Create Account
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default LoginPage;
