import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Modal,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import "./Design.css";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useAuth } from "./AuthContext";
import Signup from "./Signup";
import InfoIcon from "@mui/icons-material/Info";
// React PDF Viewer imports
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import {
  getAllMaterialTypes,
  getAllCategoriesByMaterialType,
  getSubcategoriesByCategory,
} from "./api"; // Adjust the import path
const theme = createTheme({
  palette: {
    primary: {
      main: "#2196f3",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

const HomePage = () => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user, isLoggedIn } = useAuth(); // Assuming you're using AuthContext
  const [demoPdfUrl, setDemoPdfUrl] = useState(null); // For storing demo PDF URL
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false); // Modal for login/signup
  const navigate = useNavigate();
  const [selectedBookForPayment, setSelectedBookForPayment] = useState(null);
  const [isSignupDialogOpen, setIsSignupDialogOpen] = useState(false);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [loadingSubcategories, setLoadingSubcategories] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const [isDetailModalOpen, setDetailModalOpen] = useState(false);

  const [filters, setFilters] = useState({
    materialType: "",
    chapterName: "",
    status: "",
    categoryName: "",
    subcategoryName: "",
  });

  // New state to handle the search text
  const [searchText, setSearchText] = useState("");

  // Fetch Material Types and Categories on component mount
  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const fetchedMaterialTypes = await getAllMaterialTypes();
        setMaterialTypes(fetchedMaterialTypes);

        // Fetch categories by selected material type if materialType filter is set
        if (filters.materialType) {
          const fetchedCategories = await getAllCategoriesByMaterialType(
            filters.materialType
          );
          setCategories(fetchedCategories);
        } else {
          setCategories([]); // Reset categories if no material type is selected
        }
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    };
    fetchDropdownData();
  }, [filters.materialType]); // Add filters.materialType as a dependency to re-fetch categories on material type change

  // Fetch Subcategories when a Category is selected
  useEffect(() => {
    const fetchSubcategories = async () => {
      if (filters.categoryName) {
        setLoadingSubcategories(true);
        try {
          const fetchedSubcategories = await getSubcategoriesByCategory(
            filters.categoryName
          );
          setSubcategories(fetchedSubcategories);
        } catch (error) {
          console.error("Error fetching subcategories:", error);
        } finally {
          setLoadingSubcategories(false);
        }
      } else {
        setSubcategories([]); // Reset subcategories if no category is selected
      }
    };

    fetchSubcategories();
  }, [filters.categoryName]);

  const handleSignupSuccess = () => {
    setIsSignupDialogOpen(false); // Close signup dialog
    if (selectedBookForPayment) {
      console.log("Proceeding to payment for:", selectedBookForPayment);
      isLoggedIn(true);
      handlePayment(selectedBookForPayment); // Continue with payment
    }
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin(); // PDF viewer layout

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await axios.get(
          "https://mahastudy.in:8443/getallMaterial"
        );
        setBooks(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching books:", error);
        setLoading(false);
      }
    };

    fetchBooks();
  }, []);
  const handleInfoClick = (book) => {
    setSelectedBook(book);
    setDetailModalOpen(true);
  };
  const handleFilterChange = (event) => {
    const { name, value } = event.target;

    // Set the new filter and reset dependent filters
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
      ...(name === "materialType" && { categoryName: "", subcategoryName: "" }), // Reset category and subcategory if materialType changes
    }));
  };

  // New function to handle search input
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };
  const closeDetailModal = () => {
    setSelectedBook(null);
    setDetailModalOpen(false);
  };

  const filteredBooks = books.filter((book) => {
    // Combine the search text condition with other filters
    const isSearchMatch = Object.values(book).some((value) =>
      String(value).toLowerCase().includes(searchText.toLowerCase())
    );

    return (
      isSearchMatch &&
      (filters.materialType === "" ||
        book.materialtype.includes(filters.materialType)) &&
      (filters.chapterName === "" ||
        book.chapterName.includes(filters.chapterName)) &&
      (filters.status === "" || book.status.includes(filters.status)) &&
      (filters.categoryName === "" ||
        book.categoryName.includes(filters.categoryName)) &&
      (filters.subcategoryName === "" ||
        book.subcategoryName.includes(filters.subcategoryName))
    );
  });
  const handlePayment = (book) => {
    console.log("Handle payment called for book:", book); // Log the book details

    // Ensure that the user is logged in
    if (!isLoggedIn) {
      console.log("User is not logged in, opening signup dialog.");
      setSelectedBookForPayment(book); // Save the selected book for later payment
      setIsSignupDialogOpen(true); // Open signup dialog
      return;
    }

    if (!window.Razorpay) {
      alert("Razorpay SDK not loaded. Please refresh the page and try again.");
      return;
    }

    const finalAmount = book.price * 100; // Convert to paisa for Razorpay
    console.log("Final amount for payment:", finalAmount); // Log final amount

    const options = {
      key: "rzp_test_vv1FCZvuDRF6lQ", // Razorpay Key
      amount: finalAmount, // Amount in paisa
      currency: "INR",
      name: "MAHASTUDY",
      description: "Plan Subscription",
      handler: async function (response) {
        alert(
          `Payment Successful. Payment ID: ${response.razorpay_payment_id}`
        );

        try {
          const addResponse = await axios.post("https://mahastudy.in:8443/add", {
            username: user.username,
            email: user.email,
            materialId: book.id,
            paidAmount: finalAmount,
          });

          console.log("User material association added:", addResponse.data);

          const link = document.createElement("a");
          link.href = book.pdfFile;
          link.download = "ebook.pdf";
          link.click();
        } catch (error) {
          console.error("Error posting user material association:", error);
          alert("An error occurred while processing your request.");
        }
      },
      prefill: {
        name: user.username,
        email: user.email,
        contact: user.contact || "1234567890",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#003366",
      },
    };

    console.log("Razorpay options:", options); // Log options before opening

    const rzp = new window.Razorpay(options);
    rzp.on("payment.failed", function (response) {
      alert(`Payment failed. Reason: ${response.error.description}`);
    });

    console.log("Opening Razorpay payment gateway...");
    rzp.open(); // Open Razorpay
  };

  const handleDemoClick = (pdfUrl) => {
    setDemoPdfUrl(pdfUrl);
    setIsModalOpen(true); // Open the modal with the PDF viewer
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setDemoPdfUrl(null); // Reset the PDF URL
  };

  const handleLoginClick = () => {
    navigate("/login"); // Navigate to the login page
    setIsAuthModalOpen(false); // Close the modal
  };

  const handleSignUpClick = () => {
    navigate("/signup"); // Navigate to the signup page
    setIsAuthModalOpen(false); // Close the modal
  };
  const handleSignupDialogClose = () => {
    setIsSignupDialogOpen(false);
  };
  useEffect(() => {
    if (isLoggedIn && selectedBookForPayment) {
      handlePayment(selectedBookForPayment);
    }
  }, [isLoggedIn]); // You can trigger this when the user logs in after signup

  if (loading) {
    return (
      <Box sx={{ textAlign: "center", mt: 5 }}>
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div>
        {/* Filter Text Fields */}
        <Box sx={{ mb: 3 }}>
          <Grid container spacing={2} className="textField-root">
            <Grid item xs={12} sm={6} md={2.4}>
              <TextField
                fullWidth
                label="Search"
                variant="outlined"
                value={searchText}
                placeholder="Enter Any Key To Search"
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* Material Type Dropdown */}
            <Grid item xs={12} sm={6} md={2.4}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  label="Material Type"
                  name="materialType"
                  value={filters.materialType}
                  onChange={handleFilterChange}
                  select
                >
                  <MenuItem value="">All</MenuItem>
                  {materialTypes.map((type) => (
                    <MenuItem key={type.id} value={type.materialtype}>
                      {type.materialtype}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            {/* Category Dropdown */}
            {/* Category Dropdown */}
            <Grid item xs={12} sm={6} md={2.4}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  label="Category"
                  name="categoryName"
                  value={filters.categoryName}
                  onChange={handleFilterChange}
                  select
                >
                  <MenuItem value="">All</MenuItem>
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.categoryName}>
                      {category.categoryName}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            {/* Subcategory Dropdown */}
            <Grid item xs={12} sm={6} md={2.4}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  label="Subcategory"
                  name="subcategoryName"
                  value={filters.subcategoryName}
                  onChange={handleFilterChange}
                  disabled={loadingSubcategories || !filters.categoryName}
                  select
                >
                  <MenuItem value="">All</MenuItem>
                  {loadingSubcategories ? (
                    <MenuItem disabled>
                      <CircularProgress size={24} />
                    </MenuItem>
                  ) : (
                    subcategories.map((subcategory) => (
                      <MenuItem
                        key={subcategory.id}
                        value={subcategory.subcategoryName}
                      >
                        {subcategory.subcategoryName}
                      </MenuItem>
                    ))
                  )}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
              <TextField
                fullWidth
                label="Search Subject Name"
                name="chapterName"
                value={filters.chapterName}
                onChange={handleFilterChange}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Box>

        {/* Display Books */}
        <Grid container spacing={3} mt={1}>
          {filteredBooks.map((book, index) => (
            <Grid item xs={12} sm={6} md={2.9} lg={2.4} key={index}>
              <Card
                className="card-root"
                sx={{
                  height: "87%",
                  padding: "8px",
                  transition: "transform 0.2s",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0 6px 15px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <CardMedia
                  component="img"
                  alt={book.title}
                  image={book.thumbnailFile}
                  sx={{
                    height: 150,
                    objectFit: "cover",
                    transition: "opacity 0.2s",
                    "&:hover": { opacity: 0.7 },
                  }}
                  onClick={() => window.open(book.thumbnailFile, "_blank")}
                />
                <Box
                  sx={{
                    backgroundColor: "#ADD8E6",
                    padding: "6px",
                    mt: 1,
                    borderRadius: "4px",
                  }}
                >
                  <CardContent>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {book.materialtype}{" "}
                      <span
                        style={{
                          color: book.status === "Available" ? "green" : "red",
                        }}
                      >
                        ({book.status})
                      </span>
                    </Typography>
                    <Typography color="purple">{book.chapterName}</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "green",
                        }}
                      >
                        Rs.{book.price}
                      </Typography>
                      <Typography
                        sx={{
                          textDecoration: "line-through",
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        Rs.{book.mrp}
                      </Typography>
                    </Box>
                    <Grid container spacing={1} sx={{ mt: 1 }}>
                      <Grid item xs={4}>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => handleDemoClick(book.demoPdf)}
                        >
                          Demo
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          size="small"
                          variant="contained"
                          color="success"
                          onClick={() => handlePayment(book)}
                        >
                          Buy
                        </Button>
                      </Grid>
                      <Grid item xs={4} sx={{ textAlign: "center" }}>
                        <Button
                          size="small"
                          variant="outlined"
                          color="info"
                          onClick={() => handleInfoClick(book)}
                          startIcon={<InfoIcon />}
                        >
                          Info
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Details Modal */}
        <Modal open={isDetailModalOpen} onClose={closeDetailModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              overflow: "auto",
            }}
          >
            {selectedBook && (
              <>
                <Typography variant="h6" component="h2" gutterBottom>
                  Book Details
                </Typography>
                <Grid container spacing={2}>
                  {/* Details on the left */}
                  <Grid item xs={12} sm={8}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="body1">
                          <strong>Material Type:</strong>{" "}
                          {selectedBook.materialtype}
                        </Typography>
                      </Grid>{" "}
                      <Grid item xs={12} sm={4}>
                        <Typography variant="body1">
                          <strong>Category:</strong> {selectedBook.categoryName}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="body1">
                          <strong>Subcategory:</strong>{" "}
                          {selectedBook.subcategoryName}
                        </Typography>
                      </Grid>{" "}
                      <Grid item xs={12} sm={4}>
                        <Typography variant="body1">
                          <strong>Subject Name:</strong>{" "}
                          {selectedBook.chapterName}
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={12} sm={4}>
                        <Typography variant="body1">
                          <strong>Price:</strong> Rs.{selectedBook.price}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="body1">
                          <strong>MRP:</strong> Rs.{selectedBook.mrp}
                        </Typography>
                      </Grid>*/}{" "}
                      <Grid item xs={12} sm={4}>
                        <Typography variant="body1">
                          <strong>Status:</strong> {selectedBook.status}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="body1">
                          <strong>Validity:</strong> {selectedBook.validity}{" "}
                          months
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography>
                          <strong>Created Date:</strong>{" "}
                          {new Date(
                            selectedBook.createdDate
                          ).toLocaleDateString()}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="body1">
                          <strong>Description:</strong>{" "}
                          {selectedBook.description || "N/A"}
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>PDF File:</strong>{" "}
                  <a
                    href={selectedBook.pdfFile}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download
                  </a>
                </Typography>
              </Grid> */}
                    </Grid>
                  </Grid>
                  {/* Image on the right */}
                  <Grid item xs={12} sm={4} sx={{ textAlign: "center" }}>
                    {/* <Typography variant="body1">
              <strong>Thumbnail:</strong>
            </Typography> */}
                    <img
                      src={selectedBook.thumbnailFile}
                      alt={selectedBook.chapterName}
                      style={{
                        width: "250px",
                        height: "auto",
                        objectFit: "cover",
                      }}
                    />
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 3 }}
                  onClick={closeDetailModal}
                >
                  Close
                </Button>
              </>
            )}
          </Box>
        </Modal>

        {/* Signup Dialog */}
        <Dialog
          open={isSignupDialogOpen}
          onClose={handleSignupDialogClose}
          maxWidth="sm"
          fullWidth
        >
          <DialogContent>
            {/* Assuming you have a Signup component */}
            <Signup onSignupSuccess={handleSignupSuccess} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSignupDialogClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* PDF Modal for Demo */}
        <Modal open={isModalOpen} onClose={closeModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              height: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            {demoPdfUrl && (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <div style={{ height: "100%", overflow: "auto" }}>
                  <Viewer fileUrl={demoPdfUrl} />
                </div>
              </Worker>
            )}
            <Button variant="contained" onClick={closeModal}>
              Close
            </Button>
          </Box>
        </Modal>
      </div>
    </ThemeProvider>
  );
};

export default HomePage;
