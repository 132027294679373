import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import { Box } from "@mui/material";
import FooterBar from "./FooterBar";

const Layout = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", // Ensure it takes the full viewport height
      }}
    >
      {/* Navbar stays at the top */}
      <Navbar />

      {/* Main content with dynamic Outlet */}
      <Box
        sx={{
          flexGrow: 1, // Ensures this section grows to take available space
          mt: "70px", // Margin top for spacing after Navbar
        }}
      >
        <Outlet />
      </Box>

      {/* Footer stays at the bottom */}
      <FooterBar />
    </Box>
  );
};

export default Layout;
