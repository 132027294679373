import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider, Box } from "@mui/material";
import Purchase from "./components/Purchase";
import Signup from "./components/Signup";
import HomePage from "./components/HomePage";
import Login from "./components/Login";
import Layout from "./components/Layout";
import { AuthProvider } from "./components/AuthContext"; // Import AuthContext

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif', // Changed to Poppins
  },
});

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        {/* Ensure that everything is inside the Router */}
        <AuthProvider> {/* Provide Auth Context */}
          <Router>
            {/* Apply the background color to the entire app container */}
            <Box
              sx={{
                minHeight: '100vh', // Ensure it covers the full screen height
                backgroundColor: '#f0f8ff', // Light, fresh color (Alice Blue)
                padding: '20px', // Optional padding
              }}
            >
              <Routes>
                {/* Automatically redirect to HomePage */}
                <Route path="/" element={<Navigate to="/layout/home" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/layout/*" element={<Layout />}>
                  <Route path="home" element={<HomePage />} />
                  <Route path="purchase" element={<Purchase />} />
                </Route>
              </Routes>
            </Box>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
