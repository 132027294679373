import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import axios from "axios";
import { useAuth } from "./AuthContext";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";

export default function Purchase() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [materials, setMaterials] = useState([]);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const { user } = useAuth();

  const zoomPluginInstance = zoomPlugin(); // Adding zoom functionality

  useEffect(() => {
    const fetchData = async () => {
      if (!user || !user.email) {
        console.error("User email is required but not available.");
        return;
      }

      try {
        const response = await axios.get(
          "https://mahastudy.in:8443/getUserSideMaterial",
          {
            params: {
              email: user.email,
            },
          }
        );

        const { materials = [] } = response.data;
        setMaterials(materials);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [user]);

  const handleDateChange = (setter) => (date) => {
    setter(date);
  };

  const filterByDateRange = (items) => {
    if (!startDate || !endDate) return items;
    return items.filter((item) => {
      const createdDate = new Date(item.createdDate);
      return createdDate >= startDate && createdDate <= endDate;
    });
  };

  const filteredMaterials = filterByDateRange(materials);

  const handlePreviewOpen = (pdfFile) => {
    setSelectedPdf(pdfFile);
  };

  const handlePreviewClose = () => {
    setSelectedPdf(null);
  };

  return (
    <Box>
   

      <Box sx={{ marginBottom: "20px", textAlign: "center" }}>
        <Grid container justifyContent="center" spacing={2} alignItems="center">
          <Grid item>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={handleDateChange(setStartDate)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Grid>
          <Grid item>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={handleDateChange(setEndDate)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      {filteredMaterials.length === 0 ? (
        <Typography>No purchases found in the selected date range.</Typography>
      ) : (
        <Grid container spacing={3}>
        {filteredMaterials.map((material) => (
          <Grid item xs={12} sm={6} md={2} lg={3} key={material.id}>
          <Card  
            className="card-root"
            sx={{
              height: "100%",
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
              },
              borderRadius: "15px",
              overflow: "hidden",
              boxShadow: "0 4px 16px rgba(0,0,0,0.1)",
              backgroundColor:"#ADD8E6" 
            }}
          >
            <CardMedia
              component="img"
              alt={material.materialtype}
              image={material.thumbnailFile || ""}
              sx={{
                height: 150,
                objectFit: "cover",
                transition: "opacity 0.2s",
                borderRadius:"8px",
                "&:hover": {
                  opacity: 0.9,
                },
              }}
            />
            <CardContent sx={{ padding: "16px"}}>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                  color: "#1976d2",
                }}
              >
                {material.materialtype} - {material.chapterName}{" "}
                <span style={{ color: "#8e24aa" }}>
                  ({material.categoryName})
                </span>
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: "0.9rem" }}
              >
                Purchased on:{" "}
                {new Date(material.createdDate).toLocaleDateString()}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  fontSize: "1rem",
                  marginTop: "10px",
                  fontWeight: "bold",
                }}
              >
                Price: ₹{material.price}
              </Typography>
              <Button
                variant="outlined"
                color="secondary"
                sx={{ marginTop: "10px", width: "100%" }}
                onClick={() => handlePreviewOpen(material.pdfFile)}
              >
                View
              </Button>
              {material.saveToDevice && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: "10px", width: "100%" }}
                >
                  <a
                    href={material.pdfFile}
                    download={`${material.chapterName || "download"}.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Download
                  </a>
                </Button>
              )}
            </CardContent>
          </Card>
        </Grid>
        
          ))}
        </Grid>
      )}

      <Dialog
        open={!!selectedPdf}
        onClose={handlePreviewClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Preview PDF</DialogTitle>
        <DialogContent dividers>
          {selectedPdf && (
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
            >
              <Viewer
                fileUrl={selectedPdf}
                plugins={[zoomPluginInstance]}
                onLoadError={(error) =>
                  console.error("Error loading PDF", error)
                }
              />
            </Worker>
          )}
        </DialogContent>
        <Button onClick={handlePreviewClose} color="primary">
          Close
        </Button>
      </Dialog>
    </Box>
  );
}
